import Axios from '../utils/axios-config';
import { APIResponse } from '../interfaces/user-info';
import { globalConfig } from '../configuration/config';

export const useUser = () => {
  const getUserInfo = (): Promise<{ data: APIResponse }> => {
    return Axios.get(`${globalConfig.config.REACT_APP_API_URL}/users/info`);
  };

  const checkAvatar = () => {
    return Axios.get(`${globalConfig.config.REACT_APP_API_URL}/users/avatar`);
  };

  const getAvatar = (index: number) => {
    return `${globalConfig.config.REACT_APP_API_URL}/users/avatar?index=${index}`;
  };

  const logout = () => {
    return Axios.get(`${globalConfig.config.REACT_APP_API_URL}/logout`);
  };

  return {
    getUserInfo,
    checkAvatar,
    getAvatar,
    logout,
  };
};
