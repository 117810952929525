import {
  BaseSyntheticEvent,
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Draggable, { DraggableEvent } from 'react-draggable';
import Form from 'react-bootstrap/Form';

import { Buttons } from '../Buttons/Buttons';
import {
  Checkbox,
  Delimiter,
  DynamicForm,
  UploadSignatureDialog,
  Values,
} from '../index';
import {
  FormField,
  InputOnChange,
  SignatureInfo,
  UploadRequest,
} from '../../interfaces';
import { INITIAL_VALUE_ADDITIONAL, INITIAL_VALUE_SIGNATURE } from './constants';

import styles from './DocumentPlaceholder.module.scss';
import { getBase64Image } from '../../utils/utils';

interface DocumentPlaceholderProps {
  handleDrag: (event: DraggableEvent) => void;
  setUploadPayload: Dispatch<SetStateAction<UploadRequest>>;
  uploadPayload: UploadRequest;
  signatureInfo: SignatureInfo;
  styleSignature: boolean;
  setStyleSignature: Dispatch<SetStateAction<boolean>>;
  checkOverlapBoxSignature?: () => void;
}

const MAX_SLIDER_VALUE = 100;
const MAX_HEIGHT_SIGNATURE = 500;
const RATIO = 4.9;

export const DocumentPlaceholder: FC<DocumentPlaceholderProps> = memo(
  ({
    handleDrag,
    setUploadPayload,
    uploadPayload,
    signatureInfo,
    styleSignature,
    setStyleSignature,
    checkOverlapBoxSignature,
  }) => {
    const [sliderValue, setSliderValue] = useState<number>(1);
    const nodeRef = useRef(null);
    const imgRef = useRef<HTMLImageElement | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    const [showUploadSignatureDialog, setUploadSignatureDialog] =
      useState(false);
    const [deleted, setDeleted] = useState<boolean>(false);

    const bodyContainer = useMemo(() => document.querySelector('body'), []);

    const onShowUploadHandler = useCallback(() => {
      (bodyContainer?.style as CSSStyleDeclaration).overflowY = 'hidden';
      setUploadSignatureDialog(true);
      setDeleted(false);
    }, [deleted]);

    const onDeleteImageHandler = useCallback(() => {
      setUploadPayload((currentState) => ({
        ...currentState,
        signatureInfo: {
          ...currentState.signatureInfo,
          image: '',
        },
      }));
      setDeleted(true);
    }, [uploadPayload]);

    const uploadButton = useMemo(
      () => ({
        label: uploadPayload.signatureInfo.image
          ? 'Replace Signature Image'
          : 'Upload Signature Image',
        iconClass: 'icon-upload-key',
        class: 'btn-outline-primary',
        onClick: onShowUploadHandler,
      }),
      [uploadPayload],
    );

    const deleteButton = useMemo(
      () => ({
        label: 'Delete image',
        iconClass: 'icon-delete-fill',
        class: 'btn-outline-primary btn-attention',
        onClick: onDeleteImageHandler,
      }),
      [uploadPayload],
    );

    const buttons = useMemo(
      () =>
        uploadPayload.signatureInfo.image
          ? [uploadButton, deleteButton]
          : [uploadButton],
      [uploadPayload, uploadButton, deleteButton],
    );

    const onChangeTextSignature = useCallback(
      ({ event }: InputOnChange) => {
        setUploadPayload((currentState) => ({
          ...currentState,
          signatureInfo: {
            ...currentState.signatureInfo,
            writeText: event?.target.checked,
          },
        }));
        !uploadPayload.signatureInfo.image && setStyleSignature(false);
      },
      [uploadPayload],
    );

    const onChangeAdditionalInfo = useCallback(
      ({ value }: InputOnChange) => {
        setUploadPayload((currentState) => ({
          ...currentState,
          signatureInfo: {
            ...currentState.signatureInfo,
            additionalTextFields: value.split(','),
          },
        }));
      },
      [uploadPayload],
    );

    const onStopDragHandler = useCallback(
      () => (event: DraggableEvent) => {
        handleDrag(event);
        const overlaped = checkOverlapBoxSignature?.();
        !overlaped && (nodeRef.current as any).setState({ x: 0, y: 0 });
      },
      [],
    );

    const onChangeSliderHandler = useCallback(
      (event: BaseSyntheticEvent) => {
        const widthCanvas = Number(
          canvasRef?.current?.attributes?.getNamedItem('width')?.value,
        );
        const heightCanvas = Number(
          canvasRef?.current?.attributes?.getNamedItem('height')?.value,
        );
        const aspectRatio = widthCanvas / heightCanvas;
        const restOfHeight = 100 / aspectRatio;
        const height = (event.target.value * RATIO * restOfHeight) / 100;
        if (
          event.target.value >= 4 &&
          event.target.value <= MAX_SLIDER_VALUE &&
          height <= MAX_HEIGHT_SIGNATURE
        ) {
          const overlaped = checkOverlapBoxSignature?.();
          if (!overlaped) {
            (nodeRef.current as any).setState({ x: 0, y: 0 });
          }
          const img = getBase64Image(
            canvasRef?.current as any,
            event.target.value * RATIO,
            height,
          );
          setUploadPayload((currentState) => ({
            ...currentState,
            signatureInfo: {
              ...currentState.signatureInfo,
              image: img,
            },
          }));
          setSliderValue(event.target.value);
        }
      },
      [uploadPayload.signatureInfo.writeText],
    );

    const noSignature = useMemo(
      () =>
        uploadPayload.signatureInfo.image === '' &&
        !uploadPayload.signatureInfo.writeText,
      [uploadPayload],
    );

    const formFieldsSignature: FormField[] = useMemo(
      () => [
        {
          type: Checkbox,
          name: 'textSignature',
          props: {
            type: 'checkbox',
            checked: true,
            label: 'Use signature text',
            labelClass: 'ps-3',
            name: 'textSignature',
            onChange: onChangeTextSignature,
          },
        },
      ],
      [uploadPayload, onChangeTextSignature],
    );

    const formFieldsAdditional: FormField[] = useMemo(
      () => [
        {
          type: Values,
          name: 'additionalFields',
          props: {
            placeholder: 'Value',
            hidden: !uploadPayload.signatureInfo.writeText,
            name: 'additionalFields',
            additionalInformation: {
              title: 'Additional Information',
              text: 'For more details, you can add additional information to your signature.',
            },
            onChange: onChangeAdditionalInfo,
          },
        },
      ],
      [uploadPayload, onChangeAdditionalInfo],
    );

    const signatureInfoBox = useMemo(
      () => (
        <>
          <p className="custom-fs-8 mb-0 helvetica-regular">
            Digitally signed by: {signatureInfo.signedBy}
          </p>
          <p className="custom-fs-8 mb-0 helvetica-regular">
            Issued by: {signatureInfo.issuer}
          </p>
          <p className="custom-fs-8 mb-0 helvetica-regular">
            Location: {signatureInfo.location}
          </p>
          <p className="custom-fs-8 mb-0 helvetica-regular">
            Date: {signatureInfo.date}
          </p>
        </>
      ),
      [signatureInfo],
    );

    const additionalInfoBox = useMemo(
      () =>
        uploadPayload?.signatureInfo?.additionalTextFields.map(
          (item, index) => (
            <p
              key={`additional-${index}`}
              className="custom-fs-8 mb-0 helvetica-regular text-break"
            >
              {item}
            </p>
          ),
        ),
      [uploadPayload],
    );

    const currentSize = useMemo(
      () => Math.round(sliderValue * RATIO),
      [sliderValue],
    );

    useEffect(() => {
      if (canvasRef?.current?.attributes?.getNamedItem('width')?.value) {
        setSliderValue(
          Math.round(
            Number(
              canvasRef?.current?.attributes?.getNamedItem('width')?.value,
            ) / RATIO,
          ),
        );
      }
    }, [canvasRef?.current?.attributes?.getNamedItem('width')?.value]);

    return (
      <div className="ms-5">
        <div className="py-4 row" id="uploadBox">
          <Buttons buttons={buttons} />
        </div>
        {!deleted && (
          <UploadSignatureDialog
            title={'Upload Signature Image'}
            imgRef={imgRef}
            canvasRef={canvasRef}
            showDialog={showUploadSignatureDialog}
            setUploadPayload={setUploadPayload}
            onClose={() => {
              setUploadSignatureDialog((state) => !state);
              (bodyContainer?.style as CSSStyleDeclaration).overflowY = 'auto';
            }}
          />
        )}
        <div>
          <DynamicForm
            inputs={formFieldsSignature}
            initialValues={INITIAL_VALUE_SIGNATURE}
          />
          {!noSignature && (
            <div className="d-flex justify-content-center align-items-center">
              <p className="custom-fs-2-1 mb-0">
                Drag and drop this signature into the uploaded document, place
                the seal at the bottom of the document (preferably) or in a
                place with the least amount of information.
              </p>
            </div>
          )}
          {noSignature && (
            <div>
              <Delimiter className="my-3 delimiter" />
              <h5>Attention</h5>
              <p className="custom-fs-2-1">
                You have not selected the signature image or the text to be
                displayed on the document. The document can still be signed,
                without any visible widget.
              </p>
            </div>
          )}
        </div>
        {!noSignature && (
          <div className="d-flex">
            <div
              id="boxSignature"
              className={`signature my-3 ${styles.withImage}`}
            >
              <Draggable
                ref={nodeRef}
                onStop={onStopDragHandler()}
                onDrag={(event) => handleDrag(event)}
                defaultClassName="cursor-pointer"
              >
                <div>
                  {uploadPayload.signatureInfo.image && (
                    <div
                      className={`${styles.signImage} ${
                        !styleSignature
                          ? 'default-sign-image'
                          : styles.noStyleSignImage
                      } row justify-content-center p-0 m-0`}
                      id="signImage"
                    >
                      <div
                        className="p-0 d-flex justify-content-center align-items-start"
                        style={{
                          overflow: 'hidden',
                          width: uploadPayload.signatureInfo.writeText
                            ? 'calc(100% - 132px)'
                            : 'auto',
                        }}
                      >
                        <img
                          className={`${
                            uploadPayload.signatureInfo.writeText
                              ? styles.imageSignaturePadding
                              : ''
                          }`}
                          src={`data:image/png;base64, ${uploadPayload.signatureInfo.image}`}
                        />
                      </div>
                      {uploadPayload.signatureInfo.writeText && (
                        <div className={`p-0 d-flex ${styles.signatureText}`}>
                          <div>
                            {signatureInfoBox}
                            {additionalInfoBox}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {uploadPayload.signatureInfo.image === '' && (
                    <div>
                      <div
                        className={`${styles.signImage} ${
                          !styleSignature
                            ? 'default-sign-image'
                            : styles.noStyleSignImage
                        }`}
                        id="signImage"
                      >
                        {signatureInfoBox}
                        {additionalInfoBox}
                      </div>
                    </div>
                  )}
                </div>
              </Draggable>
            </div>
          </div>
        )}
        {uploadPayload.signatureInfo.image && (
          <>
            <p className="mb-1 custom-fs-2-1">Resize Signature</p>
            <div className="slider-range">
              <div className="d-flex bg-white py-1 align-items-center">
                <div className="px-2">
                  <i className="icon-zoom-in" />
                </div>
                <div className="w-100 d-flex">
                  <Form.Range
                    value={sliderValue}
                    onChange={onChangeSliderHandler}
                  />
                </div>
                <div className="px-2">
                  <i className="icon-zoom-out" />
                </div>
              </div>
              <p className="pt-2 custom-fs-2-1">
                Current size: {currentSize} (px)
              </p>
            </div>
          </>
        )}
        {uploadPayload.signatureInfo.writeText && (
          <Delimiter className="delimiter" />
        )}
        <DynamicForm
          inputs={formFieldsAdditional}
          initialValues={INITIAL_VALUE_ADDITIONAL}
        />
      </div>
    );
  },
);
