import { stringify } from 'query-string';
import { saveAs } from 'file-saver';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

import {
  DocumentInfoResponse,
  HistoryFilters,
  HistoryResponse,
  UploadRequest,
  UploadResponse,
  VerifyDocumentRequest,
  VerifyResponse,
} from '../interfaces';
import Axios from '../utils/axios-config';
import { VerifyDocumentResponse } from '../interfaces/api-response';
import { globalConfig } from '../configuration/config';

export const useDocument = () => {
  const uploadDocument = async (
    payload: UploadRequest,
  ): Promise<{ data: UploadResponse }> => {
    return await Axios.post<UploadResponse>(
      `${globalConfig.config.REACT_APP_API_URL}/pdf/upload`,
      payload,
    );
  };

  const getDocumentById = (id: string): Promise<AxiosResponse> => {
    return Axios.get(`${globalConfig.config.REACT_APP_API_URL}/pdf/${id}`, {
      responseType: 'blob',
    });
  };

  const downloadFile = async (id: string, fileName: string): Promise<void> => {
    return getDocumentById(id).then(({ data }) => saveAs(data, fileName));
  };

  const verifySignature = (
    payload: VerifyDocumentRequest,
    config?: AxiosRequestConfig,
  ): Promise<VerifyResponse> => {
    return Axios.post<VerifyDocumentResponse>(
      `${globalConfig.config.REACT_APP_API_URL}/pdf/verifySignature`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        ...config,
      },
    );
  };

  const cancelDocumentSign = (id: string) => {
    return Axios.delete(
      `${globalConfig.config.REACT_APP_API_URL}/pdf/${id}/cancel`,
    );
  };

  const getDocumentInfo = (
    id: string,
  ): Promise<{ data: DocumentInfoResponse }> => {
    return Axios.get(
      `${globalConfig.config.REACT_APP_API_URL}/pdf/${id}/documentInfo`,
    );
  };

  const getHistory = (
    filters: HistoryFilters,
  ): Promise<{ data: HistoryResponse }> => {
    return Axios.get(
      `${globalConfig.config.REACT_APP_API_URL}/pdf/history?${stringify(
        filters,
      )}`,
    );
  };

  const renewOtpCode = (id: string): Promise<{ data: string }> => {
    return Axios.get(
      `${globalConfig.config.REACT_APP_API_URL}/pdf/${id}/otpRenew`,
    );
  };

  const archiveDocument = (id: string) => {
    return Axios.patch(
      `${globalConfig.config.REACT_APP_API_URL}/pdf/${id}/archive?isArchived=true`,
    );
  };

  const restoreDocument = (id: string) => {
    return Axios.patch(
      `${globalConfig.config.REACT_APP_API_URL}/pdf/${id}/archive?isArchived=false`,
    );
  };

  const archiveMultipleDocuments = (documentIds: string[]) => {
    return Axios.patch(
      `${globalConfig.config.REACT_APP_API_URL}/pdf/archive/batch`,
      documentIds,
    );
  };

  const deleteMultipleDocuments = (documentIds: string[]) => {
    return Axios.delete(`${globalConfig.config.REACT_APP_API_URL}/pdf`, {
      data: documentIds,
    });
  };

  const getCertificatesIssuer = (): Promise<{ data: { issuer: string } }> => {
    return Axios.get(
      `${globalConfig.config.REACT_APP_API_URL}/certificates/issuer`,
    );
  };

  return {
    uploadDocument,
    getDocumentById,
    downloadFile,
    verifySignature,
    cancelDocumentSign,
    getDocumentInfo,
    getHistory,
    renewOtpCode,
    archiveDocument,
    restoreDocument,
    archiveMultipleDocuments,
    deleteMultipleDocuments,
    getCertificatesIssuer,
  };
};
