import axios, { AxiosResponse } from 'axios';

import { routes } from '../models';

const Axios = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  responseType: 'json',
  withCredentials: true,
});

Axios.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error) => {
    if (error?.response?.status === 401) {
      window.location.replace(routes.login);
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  },
);

export default Axios;
