import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';

import { Modal } from '../';
import { EXPIRED_URL_KEY } from '../../constants';
import { routes } from '../../models';
import { useUser } from '../../hooks';
import { globalConfig } from '../../configuration/config';

import styles from './IdleTimer.module.scss';

const TIMEOUT = 1_800_000;
const PROMPT_BEFORE_IDLE = 59_000;

const IdleIcon = () => (
  <i style={{ fontSize: '68px' }} className="icon-idle-timer text-warning" />
);

export const IdleTimer: FC = memo(() => {
  const [remaining, setRemaining] = useState<number>(TIMEOUT);
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { getUserInfo, logout } = useUser();

  const onIdle = useCallback(
    (showNotification = false) =>
      () => {
        if (showNotification) {
          const expirationFlag =
            (showNotification && `?${EXPIRED_URL_KEY}=${showNotification}`) ||
            '';
          logout();
          navigate(`${routes.login}${expirationFlag}`);
        } else {
          window.location.replace(
            `${globalConfig.config.REACT_APP_API_URL}/logout?redirectUri=${window.location.origin}`,
          );
        }
      },
    [],
  );

  const onActive = useCallback(() => {
    setOpen(false);
  }, []);

  const onPrompt = useCallback(() => {
    setOpen(true);
  }, []);

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle: onIdle(true),
    onActive,
    onPrompt,
    timeout: TIMEOUT,
    promptBeforeIdle: PROMPT_BEFORE_IDLE,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = useCallback(() => {
    getUserInfo();
    activate();
  }, []);

  const minutes = Math.floor(remaining / 60);
  const seconds = remaining - minutes * 60;
  const secondsKey = seconds > 1 ? 'seconds' : 'second';

  return createPortal(
    <>
      {open && (
        <Modal>
          <div className="d-flex py-3 justify-content-between">
            <div className="d-inline-flex">
              <div className="px-3">
                <IdleIcon />
              </div>
            </div>
            <div className="w-100 d-block">
              <div>
                <p className={`mb-0 open-sans-semibold ${styles.title}`}>
                  Your online session will expire in{' '}
                </p>
              </div>
              <div>
                <p
                  className={`mb-0 text-warning open-sans-light ${styles.timer}`}
                >{`${minutes} min ${seconds} ${secondsKey}`}</p>
              </div>
              <div>
                <p className={`mb-0 open-sans-medium ${styles.subTitle}`}>
                  {
                    'Please click "Continue" to keep working or click "Sign Out" to end your session now'
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex py-3 justify-content-between">
            <button
              type="button"
              className={`d-inline-flex justify-content-center btn btn-outline-primary ${styles.button}`}
              onClick={onIdle()}
            >
              Sign Out
            </button>
            <button
              type="button"
              className={`d-inline-flex justify-content-center align-items-center btn btn-primary text-white ${styles.button}`}
              onClick={handleStillHere}
            >
              Continue
            </button>
          </div>
        </Modal>
      )}
    </>,
    document.querySelector('#root') as Element,
  );
});
