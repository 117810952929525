import { useState } from 'react';
import { Link } from 'react-router-dom';

import { FeedbackDialog } from '../index';
import DotgovFooterLogo from '../../assets/images/dotgov-footer-logo.webp';
import ZamFooterLogo from '../../assets/images/zam-footer-logo.svg';
import styles from './Footer.module.scss';
import { routes } from '../../models';
import { DOTGOV_FOOTER_TEXT, ZAM_FOOTER_TEXT } from '../../constants';
import { Theme } from '../../enums';
import { globalConfig } from '../../configuration/config';

export const Footer = () => {
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);

  return (
    <footer className="bottom-0 w-100 pt-2 position-relative bg-grey-2">
      <div className={`${styles.footer} container pt-2 pb-1`}>
        <div className="row bg-grey-2 my-5">
          <div className="col-md-6 col-lg-3">
            <div className="logo">
              <img
                src={
                  globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
                    ? ZamFooterLogo
                    : DotgovFooterLogo
                }
                alt="DotGov Sign"
                className="img-fluid mb-3"
              />
            </div>
            <div className="w-75">
              <p className="p5-700 text-description">
                {globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
                  ? 'Zam Sign - E-Government Digital Signature Service'
                  : 'DotGov Sign - E-Government Digital Signature Service'}
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <p className="p1-300">Useful Links</p>
            <ul className="p-0">
              <Link
                to={routes.termsAndConditions}
                className="nav-link text-link p-0 my-3 p2-600"
              >
                Terms and Conditions
              </Link>
              <Link
                to={routes.privacyPolicy}
                className="nav-link text-link p-0 p2-600"
              >
                Privacy Policy
              </Link>
            </ul>
          </div>
          <div className="col-md-6 col-lg-3 px-4">
            <p className="mb-2 p1-300">Support</p>
            <div className="mb-2">
              <p className="mb-1 p2-600">Email:</p>
              <a
                href="mailto: infotest@gmail.com"
                className="text-main p2-400 text-decoration-none"
              >
                infotest@gmail.com
              </a>
            </div>
            <p className="mb-1 p2-600">Phone:</p>
            <a className="text-main p2-400 text-decoration-none">+373 </a>
            <a
              href="tel:+3733468 456 567 889"
              className="p2-400 text-decoration-none text-primary"
            >
              3468 456 567 889
            </a>
          </div>
          <div className="col-md-6 col-lg-3">
            <p className="title mb-2 p1-300">Contact</p>
            <button
              className="btn btn-outline-primary semi-bold custom-fs-1 py-2 px-3"
              onClick={() => setShowFeedbackDialog(!showFeedbackDialog)}
            >
              Leave feedback
            </button>
            {showFeedbackDialog && (
              <FeedbackDialog
                title="Support feedback"
                onClose={() => setShowFeedbackDialog(false)}
              />
            )}
          </div>
        </div>
      </div>

      <div className="bg-white py-1">
        <div
          className={`${styles.copyright} copyright container semi-bold fst-normal`}
        >
          <span className="text-disabled custom-fs-6">
            {globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
              ? ZAM_FOOTER_TEXT
              : DOTGOV_FOOTER_TEXT}
          </span>
        </div>
      </div>
    </footer>
  );
};
