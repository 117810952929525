export interface DynamicConfig {
  REACT_APP_API_URL: string;
  REACT_APP_AUTH_URL: string;
  REACT_APP_STYLES: string;
  REACT_APP_IOS_STORE_URL: string;
  REACT_APP_GOOGLE_STORE_URL: string;
  REACT_APP_DYNAMIC_STORE_URL: string;
}

export const defaultConfig: DynamicConfig = {
  REACT_APP_API_URL: 'https://dotgovsign.dotgov.uk',
  REACT_APP_AUTH_URL: 'https://pass.dotgov.uk',
  REACT_APP_STYLES: 'lightDotgov',
  REACT_APP_IOS_STORE_URL: 'https://testflight.apple.com/join/43pYIaNE',
  REACT_APP_GOOGLE_STORE_URL:
    'https://play.google.com/store/apps/details?id=net.dotgovsolutions.zammobile',
  REACT_APP_DYNAMIC_STORE_URL: 'https://pass.dotgov.uk/dg/mobile',
};

class GlobalConfig {
  config: DynamicConfig = defaultConfig;
  notDefined = true;

  get(): DynamicConfig {
    if (this.notDefined) {
      throw new Error(
        'Global config has not been defined yet. Be sure to call the getter only after the config has been downloaded and set.',
      );
    } else {
      return this.config;
    }
  }

  set(value: DynamicConfig): void {
    if (this.notDefined) {
      this.config = value;
      this.notDefined = false;
    } else {
      throw new Error(
        'Global config has already been defined and now has been called second time. This is probably not intended.',
      );
    }
  }
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = 'config.json';
