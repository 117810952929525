import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './store/AuthContext';
import store from './store';
import 'swiper/css/bundle';
import 'bootstrap/dist/js/bootstrap.bundle';
import { globalConfig, globalConfigUrl } from './configuration/config';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const app: ReactElement = (
  <React.StrictMode>
    <Provider store={store}>
      <AuthContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthContextProvider>
    </Provider>
  </React.StrictMode>
);

fetch(`${window.location.origin}/${globalConfigUrl}`)
  .then((r) => r.json())
  .then((data) => {
    globalConfig.set(data);
    return app;
  })
  .then((reactElement: ReactElement) => {
    root.render(reactElement);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
