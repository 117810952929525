import { useState, useContext, Suspense, useEffect, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { AxiosError } from 'axios';

import { Navigation } from './navigation/Navigation';
import { useUser } from './hooks';
import { AuthContext } from './store/AuthContext';
import { DEFAULT_ERROR_MESSAGE, THEMES } from './constants';
import { routes } from './models';
import { globalConfig } from './configuration/config';
import { Favicon, HeadTitle, Theme } from './enums';

import './App.scss';

export const App = () => {
  const [session, setSession] = useState<boolean>(false);
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const { getUserInfo } = useUser();
  const theme = useMemo(
    () =>
      Object.keys(THEMES).find(
        (key: string) => key === globalConfig.config.REACT_APP_STYLES,
      ),
    [],
  );

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    let title = document.querySelector('title') as HTMLTitleElement;
    const head = document.getElementsByTagName('head')[0];

    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      link.href = `${window.location.origin}/${
        globalConfig.config.REACT_APP_STYLES === Theme.LIGHTDOTGOV
          ? Favicon.DotGov
          : Favicon.Zam
      }.ico`;
      head.appendChild(link);
    }
    if (!title) {
      title = document.createElement('title');
      title.innerText =
        globalConfig.config.REACT_APP_STYLES === Theme.LIGHTDOTGOV
          ? HeadTitle.DotGov
          : HeadTitle.Zam;
      head.appendChild(title);
    }
  }, []);

  useEffect(() => {
    getUserInfo()
      .then(({ data }) => {
        if (data?.isAuthenticated) {
          if (Number(data.claims?.trustLevel) <= 1) {
            navigate(`${routes.unverified}`);
          }
          context.login(data);
        } else {
          context.logout();
        }
      })
      .catch((error: AxiosError) => {
        toast.error(error.message || DEFAULT_ERROR_MESSAGE);
        context.logout();
      })
      .finally(() => {
        setSession(true);
      });
  }, []);
  return (
    <ThemeSwitcherProvider
      defaultTheme={theme || Theme.LIGHTDOTGOV}
      themeMap={THEMES}
    >
      <Suspense fallback="Loading application layout, wait please">
        {session && <Navigation />}
        <ToastContainer position="bottom-right" />
      </Suspense>
    </ThemeSwitcherProvider>
  );
};
