import React, { useContext } from 'react';
import { AuthContext } from '../store/AuthContext';
import { Navigate } from 'react-router-dom';
import { routes } from '../models';
import { IdleTimer } from '../components';

export const ProtectedRoute = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const { isAuthenticated } = useContext(AuthContext);
  if (!isAuthenticated) {
    return <Navigate to={routes.login} />;
  }
  return (
    <>
      <IdleTimer />
      {children}
    </>
  );
};
