import { FC, memo, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  ZAM_FOOTER_TEXT,
  DOTGOV_FOOTER_TEXT,
  EXPIRED_URL_KEY,
} from '../../constants';
import { LoginForm } from '../../components';
import { Theme } from '../../enums';

import { Message } from '../../constants/';
import { globalConfig } from '../../configuration/config';

import styles from './Login.module.scss';

export const Login: FC = memo(() => {
  const [searchParams] = useSearchParams();
  const isExpired = searchParams.get(EXPIRED_URL_KEY);

  const handleSubmit = () => {
    window.location.replace(
      `${globalConfig.config.REACT_APP_API_URL}/login?redirectUri=${window.location.origin}`,
    );
  };

  useEffect(() => {
    isExpired &&
      toast.info(Message.SESSION_EXPIRATION, {
        autoClose: false,
      });
  }, []);

  return (
    <div className={`${styles.loginWrapper} container-fluid min-vh-100`}>
      <div className="row justify-content-center align-content-center min-vh-95 flex-column">
        <LoginForm
          handleSubmit={handleSubmit}
          buttonLabel={'Sign in with ZamPass'}
          verified={true}
        />
      </div>
      <footer className="d-flex justify-content-center text-white p5-400">
        {globalConfig.config.REACT_APP_STYLES === Theme.LIGHTZAM
          ? ZAM_FOOTER_TEXT
          : DOTGOV_FOOTER_TEXT}
      </footer>
    </div>
  );
});
